import { Footer } from "widgets/footer/footer";
import { Header } from "widgets/header/header";
import s from "./careers-page.module.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as Effect } from "./assets/effect.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "./assets/1.jpg";
import slider2 from "./assets/2.jpg";
import slider3 from "./assets/3.jpg";
import slider4 from "./assets/4.jpg";
import { ReactComponent as GraduateIcon } from "./assets/graduate.svg";
import { ReactComponent as JobsIcon } from "./assets/jobs.svg";
import { ReactComponent as StoriesIcon } from "./assets/stories.svg";
import { Button } from "shared/ui-kit/button/button";
import { ReactComponent as InsuranceIcon } from "./assets/insurance.svg";
import { ReactComponent as VacationIcon } from "./assets/vacation.svg";
import { ReactComponent as BalanceIcon } from "./assets/balance.svg";
import { ReactComponent as MaternityIcon } from "./assets/maternity.svg";
import { ReactComponent as CarIcon } from "./assets/car.svg";
import { ReactComponent as SickIcon } from "./assets/sick.svg";
import { ReactComponent as BonusIcon } from "./assets/bonus.svg";
import { ReactComponent as DiscountIcon } from "./assets/discount.svg";

export const CareersPage = () => {
  return (
    <>
      <Header />
      <MainSection />
      <Footer />
    </>
  );
};

const MainSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className={s.banner}>
        <div className={s.filter} />
        <div className={s.container}>
          <h1 className={s.title}>
            {t("careersPage.title1")}
            <br />
            {t("careersPage.title2")}
          </h1>
          <p className={s.bannerText}>{t("careersPage.bannerText")}</p>
        </div>
        <Effect className={s.effect} />
      </section>
      <main className={s.main}>
        <section className={s.slider}>
          <Slider
            dots
            infinite
            speed={500}
            slidesToShow={3}
            slidesToScroll={1}
            responsive={[
              {
                breakpoint: 1140,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            <img src={slider1} alt="" />
            <img src={slider2} alt="" />
            <img src={slider3} alt="" />
            <img src={slider4} alt="" />
          </Slider>
        </section>
        <section className={s.subtitleSection}>
          <h3 className={s.subtitle}>{t("careersPage.subtitle")}</h3>
          <p className={s.subtitleText}>{t("careersPage.subtitleText")}</p>
        </section>
        <section className={s.cards}>
          {cards.map(({ icon, key }) => (
            <article key={key} className={s.card}>
              {icon}
              <h6 className={s.cardTitle}>
                {t(`careersPage.cards.${key}.title`)}
              </h6>
              <p className={s.cardText}>{t(`careersPage.cards.${key}.text`)}</p>
            </article>
          ))}
        </section>
        <section className={s.applySection}>
          <h4 className={s.applyTitle}>{t(`careersPage.applyTitle`)}</h4>
          <p className={s.applyText}>{t(`careersPage.applyText`)}</p>
          <Button>{t(`careersPage.applyButton`)}</Button>
        </section>
        <section className={s.perksSection}>
          <h4 className={s.perksTitle}>{t(`careersPage.perksTitle`)}</h4>
          <p className={s.perksText}>{t(`careersPage.perksText`)}</p>
          <section className={s.perksItems}>
            {perks.map(({ icon, key }) => (
              <article key={key} className={s.perk}>
                {icon}{" "}
                <span className={s.perkTitle}>
                  {t(`careersPage.perks.${key}`)}
                </span>
              </article>
            ))}
          </section>
        </section>
      </main>
    </>
  );
};

const cards = [
  { key: "graduate", icon: <GraduateIcon className={s.cardIcon} /> },
  { key: "jobs", icon: <JobsIcon className={s.cardIcon} /> },
  { key: "stories", icon: <StoriesIcon className={s.cardIcon} /> },
];

const perks = [
  { key: "insurance", icon: <InsuranceIcon className={s.perkIcon} /> },
  { key: "vacation", icon: <VacationIcon className={s.perkIcon} /> },
  { key: "balance", icon: <BalanceIcon className={s.perkIcon} /> },
  { key: "maternity", icon: <MaternityIcon className={s.perkIcon} /> },
  { key: "car", icon: <CarIcon className={s.perkIcon} /> },
  { key: "sick", icon: <SickIcon className={s.perkIcon} /> },
  { key: "bonus", icon: <BonusIcon className={s.perkIcon} /> },
  { key: "discount", icon: <DiscountIcon className={s.perkIcon} /> },
];
