import { Header } from "widgets/header/header";
import { Footer } from "widgets/footer/footer";
import s from "./about-us-page.module.css";
import { ReactComponent as BgEffect } from "./assets/bg-effect.svg";
import { useTranslation } from "react-i18next";
import goalsImage from "./assets/goals.png";
import { ReactComponent as HumorIcon } from "./assets/humor.svg";
import { ReactComponent as InclusivityIcon } from "./assets/inclusivity.svg";
import { ReactComponent as VulnerabilityIcon } from "./assets/vulnerability.svg";
import { ReactComponent as ResourcefulnessIcon } from "./assets/resourcefulness.svg";
import { ReactComponent as CuriosityIcon } from "./assets/curiosity.svg";
import { Slide } from "react-awesome-reveal";

export const AboutUsPage = () => (
  <>
    <Header />
    <AboutUsMain />
    <Footer />
  </>
);

const AboutUsMain = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className={s.banner}>
        <div className={s.filter} />
        <BgEffect className={s.bannerEffect} />
        <div className={s.bannerWrapper}>
          <Slide direction="left" triggerOnce duration={1500}>
            <h2 className={s.title}>{t("aboutUsPage.title1")}</h2>
          </Slide>
          <Slide direction="up" triggerOnce duration={1500}>
            <h3 className={s.title2}>{t("aboutUsPage.title2")}</h3>
          </Slide>
          <Slide direction="right" triggerOnce duration={1500}>
            <h2 className={s.title}>{t("aboutUsPage.title3")}</h2>
          </Slide>
          <Slide direction="up" triggerOnce duration={2000}>
            <p className={s.bannerText}>{t("aboutUsPage.bannerText")}</p>
          </Slide>
        </div>
      </section>
      <section className={s.goals}>
        <div className={s.row}>
          <Slide direction="left" triggerOnce>
            <article className={s.article}>
              <h4 className={s.articleTitle}>{t("aboutUsPage.goalsTitle")}</h4>
              <p className={s.articleText}>{t("aboutUsPage.goalsText")}</p>
            </article>
          </Slide>
          <Slide direction="right" triggerOnce>
            <img alt="our goals" src={goalsImage} style={{ width: "100%" }} />
          </Slide>
        </div>
      </section>
      <section className={s.articles}>
        <BgEffect className={s.bannerEffect2} />
        <div className={s.filter2} />
        <div className={s.articlesBody}>
          <div className={s.row}>
            <Slide direction="left" triggerOnce>
              <article className={s.article}>
                <h4 className={s.articleTitle}>
                  {t("aboutUsPage.missionTitle")}
                </h4>
                <p className={s.articleText}>{t("aboutUsPage.missionText")}</p>
              </article>
            </Slide>
            <Slide direction="right" triggerOnce>
              <article className={s.article}>
                <h4 className={s.articleTitle}>
                  {t("aboutUsPage.visionTitle")}
                </h4>
                <p className={s.articleText}>{t("aboutUsPage.visionText")}</p>
              </article>
            </Slide>
          </div>
        </div>
      </section>
      <section className={s.companyValues}>
        <Slide direction="up" triggerOnce>
          <h4 className={s.articleTitle}>{t("aboutUsPage.valuesTitle")}</h4>
        </Slide>
        <ul className={s.companyValuesItems}>
          {companyValues.map(({ icon, key }) => (
            <CompanyValue
              key={key}
              icon={icon}
              title={t(`aboutUsPage.companyValues.${key}.title`)}
              text={t(`aboutUsPage.companyValues.${key}.text`)}
            />
          ))}
        </ul>
      </section>
    </>
  );
};

const companyValues = [
  { key: "humor", icon: <HumorIcon /> },
  { key: "inclusivity", icon: <InclusivityIcon /> },
  { key: "vulnerability", icon: <VulnerabilityIcon /> },
  { key: "resourcefulness", icon: <ResourcefulnessIcon /> },
  { key: "curiosity", icon: <CuriosityIcon /> },
];

const CompanyValue = ({
  icon,
  title,
  text,
}: {
  icon: JSX.Element;
  title: string;
  text: string;
}) => (
  <li className={s.companyValue}>
    <div className={s.iconWrapper}>{icon}</div>
    <div>
      <div className={s.companyValueTitle}>{title}</div>
      <div className={s.companyValueText}>{text}</div>
    </div>
  </li>
);
