import { RouterProvider } from 'react-router-dom';
import { router } from 'pages/router';
import 'shared/i18n/init-i18n';
import 'react-toastify/dist/ReactToastify.css';
import Toaster from '../shared/Toaster';

export const App = () => {
  return (
    <Toaster>
      <RouterProvider router={router}/>
    </Toaster>

  );
};
