import { useTranslation } from "react-i18next";
import { Footer } from "widgets/footer/footer";
import { Header } from "widgets/header/header";
import s from "./real-estate.module.css";
import banner from "./assets/banner.jpg";
import support from "./assets/support.jpg";

import { ReactComponent as Effect } from "./assets/effect.svg";
import { Button, ButtonSize } from "shared/ui-kit/button/button";
export const RealEstatePage = () => {
  return (
    <>
      <Header />
      <MainSection />
      <Footer />
    </>
  );
};

const MainSection = () => {
  const { t } = useTranslation();

  return (
    <main className={s.root}>
      <section className={s.banner}>
        <div className={s.filter} />
        <Effect className={s.effect} />
        <div className={s.left}>
          <h2 className={s.subtitle}>{t("realEstate.subtitle")}</h2>
          <h2 className={s.title}>{t("realEstate.title")}</h2>
          <Button size={ButtonSize.Medium}>{t("realEstate.button")}</Button>
        </div>
        <div className={s.right}>
          <img src={banner} alt="" />
        </div>
      </section>
      <section className={s.successSection}>
        <div className={s.successWrapper}>
          <p className={s.successText}>{t("realEstate.successText1")}</p>
          <h6 className={s.successTitle}>{t("realEstate.successTitle")}</h6>
          <p className={s.successText}>{t("realEstate.successText2")}</p>
        </div>
      </section>
      <section className={s.supportSection}>
        <div className={s.supportWrapper}>
          <div className={s.img}>
            <img src={support} alt="" />
          </div>
          <div className={s.supportTextSection}>
            <h5 className={s.supportTitle}>{t("realEstate.supportTitle")}</h5>
            <h6 className={s.supportSubtitle}>
              {t("realEstate.supportSubtitle")}
            </h6>
            <p className={s.supportText}>{t("realEstate.supportText")}</p>
          </div>
        </div>
      </section>
    </main>
  );
};
