import { Header } from "widgets/header/header";
import { Footer } from "widgets/footer/footer";
import { BannerSection } from "./sections/banner-section/banner-section";
import { MainSection } from "./sections/main-section/main-section";

export const HomePage = () => (
  <>
    <Header />
    <BannerSection />
    <MainSection />
    <Footer />
  </>
);
