import { PageUnderConstruction } from "shared/ui-kit/pageUnderConstruction/page-under-construction";
import { Footer } from "widgets/footer/footer";
import { Header } from "widgets/header/header";

export const BusinessProcessPage = () => {
  return (
    <>
      <Header />
      <PageUnderConstruction />
      <Footer />
    </>
  );
};
