import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "./home-page/home-page";
import { AboutUsPage } from "./about-us-page/about-us-page";
import { CareersPage } from "./careers-page/careers-page";
import { BusinessProcessPage } from "./business-process-page/business-process-page";
import { IndustriesPage } from "./industries-page/industries-page";
import { RealEstatePage } from "./real-estate-page/real-estate-page";
import { routes } from "shared/routes";

export const router = createBrowserRouter([
  {
    path: routes.home,
    element: <HomePage />,
  },
  {
    path: routes.aboutUs,
    element: <AboutUsPage />,
  },
  {
    path: routes.careers,
    element: <CareersPage />,
  },
  {
    path: routes.businessProcess,
    element: <BusinessProcessPage />,
  },
  {
    path: routes.industries,
    children: [
      {
        index: true,
        element: <IndustriesPage />,
      },
      {
        path: routes.industriesRealEstate,
        element: <RealEstatePage />,
      },
    ],
  },
]);
