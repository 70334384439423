import cn from "classnames";
import s from "./button.module.css";

export enum ButtonSize {
  Small = "small",
  Medium = "medium",
  Big = "big",
}

export const Button = ({
  size = ButtonSize.Medium,
  href,
  children,
  className,
  withoutHover,
  type = 'button',
}: {
  size?: ButtonSize;
  href?: string;
  children: string;
  className?: string;
  withoutHover?: boolean;
  type?: 'button' | 'submit' | 'reset';
}) => {
  const classNames = cn(s.root, className, {
    [s.medium]: size === ButtonSize.Medium || size === ButtonSize.Big,
    [s.withHover]: !withoutHover,
    [s.big]: size === ButtonSize.Big,
  });

  if (href !== undefined) {
    return (
      <a href={href} className={classNames}>
        {children}
      </a>
    );
  }

  return (
    <button type={type} className={classNames}>
      {children}
    </button>
  );
};
