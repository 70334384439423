import { useTranslation } from "react-i18next";
import s from "./main-section.module.css";
import { Button, ButtonSize } from "shared/ui-kit/button/button";
import img1 from "./assets/1.webp";
import img2 from "./assets/2.jpg";
import img3 from "./assets/3.jpeg";
import img4 from "./assets/4.webp";
import img5 from "./assets/5.webp";
import img6 from "./assets/6.jpeg";
import { Fade, Slide } from "react-awesome-reveal";
import React from "react";

export const MainSection = () => {
  const { t } = useTranslation();

  return (
    <main className={s.root}>
      <Slide direction="right" style={{ width: "fit-content" }} triggerOnce>
        <h5 className={s.title}>
          {t("homePage.main.title1")} <b>{t("homePage.main.title2")}</b>
        </h5>
      </Slide>
      <section className={s.items}>
        {itemsImages.map(({ id, image, titleKey, textKey, animation }) => (
          <React.Fragment key={id}>
            {animation(
              <div className={s.item}>
                <div
                  className={s.itemImage}
                  style={{ backgroundImage: `url(${image})` }}
                />
                <div className={s.itemBody}>
                  <h6 className={s.itemTitle}>
                    {t(`homePage.main.items.title.${titleKey}`)}
                  </h6>
                  <p className={s.itemText}>
                    {t(`homePage.main.items.text.${textKey}`)}
                  </p>
                  <Button href="/#">{t("homePage.main.viewMore")}</Button>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </section>
      <Slide direction="up" triggerOnce>
        <section className={s.contactUs}>
          <article className={s.contactUsLeft}>
            <h6 className={s.contactUsTitle}>
              {t("homePage.main.contactUs.title")}
            </h6>
            <p className={s.contactUsText}>
              {t("homePage.main.contactUs.text")}
            </p>
          </article>
          <article className={s.contactUsRight}>
            <Button size={ButtonSize.Big} href="/#">
              {t("homePage.main.contactUs.getAlerts")}
            </Button>
          </article>
        </section>
      </Slide>
    </main>
  );
};

const itemsImages = [
  {
    id: 1,
    titleKey: "item1",
    textKey: "item1",
    image: img1,
    animation: (children: React.ReactNode) => (
      <Slide direction="left" triggerOnce>
        {children}
      </Slide>
    ),
  },
  {
    id: 2,
    titleKey: "item2",
    textKey: "item2",
    image: img2,
    animation: (children: React.ReactNode) => (
      <Fade triggerOnce>{children}</Fade>
    ),
  },
  {
    id: 3,
    titleKey: "item3",
    textKey: "item3",
    image: img3,
    animation: (children: React.ReactNode) => (
      <Slide direction="right" triggerOnce>
        {children}
      </Slide>
    ),
  },
  {
    id: 4,
    titleKey: "item4",
    textKey: "item4",
    image: img4,
    animation: (children: React.ReactNode) => (
      <Slide direction="left" triggerOnce>
        {children}
      </Slide>
    ),
  },
  {
    id: 5,
    titleKey: "item5",
    textKey: "item5",
    image: img5,
    animation: (children: React.ReactNode) => (
      <Fade triggerOnce>{children}</Fade>
    ),
  },
  {
    id: 6,
    titleKey: "item6",
    textKey: "item6",
    image: img6,
    animation: (children: React.ReactNode) => (
      <Slide direction="right" triggerOnce>
        {children}
      </Slide>
    ),
  },
];
