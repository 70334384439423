import { useTranslation } from "react-i18next";
import { Footer } from "widgets/footer/footer";
import { Header } from "widgets/header/header";
import s from "./industries-page.module.css";
import img1 from "./assets/1.jpg";
import img2 from "./assets/2.png";
import img3 from "./assets/3.jpg";
import img4 from "./assets/4.jpg";

export const IndustriesPage = () => {
  return (
    <>
      <Header />
      <MainSection />
      <Footer />
    </>
  );
};

const MainSection = () => {
  const { t } = useTranslation();

  return (
    <main className={s.root}>
      <div>
        <img className={s.image} alt="" src={img1} />
      </div>
      <article className={s.block}>
        <h4 className={s.title}>{t("industriesPage.serviceTitle")}</h4>
        <p className={s.text}>{t(t("industriesPage.serviceText"))}</p>
        <a href="/#" className={s.button}>
          {t("industriesPage.buttonText")}
        </a>
      </article>
      <article className={s.block}>
        <h4 className={s.title}>{t("industriesPage.aiTitle")}</h4>
        <p className={s.text}>{t(t("industriesPage.aiText"))}</p>
        <a href="/#" className={s.button}>
          {t("industriesPage.buttonText")}
        </a>
      </article>
      <div>
        <img className={s.image} alt="" src={img2} />
      </div>
      <div>
        <img className={s.image} alt="" src={img3} />
      </div>
      <article className={s.block}>
        <p className={s.description}>
          {t("industriesPage.text-3-1")}
          <br />
          {t("industriesPage.text-3-2")}
        </p>
        <a href="/#" className={s.button}>
          {t("industriesPage.buttonText")}
        </a>
      </article>
      <article className={s.quoteBlock}>
        <p className={s.quote}>
          {t("industriesPage.text-4-1")}
          <br />
          <br />
          {t("industriesPage.text-4-2")}
        </p>
        <a href="/#" className={s.button}>
          {t("industriesPage.buttonText")}
        </a>
      </article>
      <div>
        <img className={s.image} alt="" src={img4} />
      </div>
    </main>
  );
};
