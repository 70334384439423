import s from "./header.module.css";
import logo from "./assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ButtonSize } from "shared/ui-kit/button/button";
import { useState } from "react";
import cn from "classnames";
import { ReactComponent as ArrowUpIcon } from "./assets/arrow-up.svg";
import { routes } from "shared/routes";

export const Header = () => {
  const { t } = useTranslation();
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <header className={s.root}>
      <div className={s.left}>
        <button
          className={cn(s.menuButton, { [s.menuButtonOpened]: menuOpened })}
          onClick={() => setMenuOpened((v) => !v)}
        >
          <div />
          <div />
          <div />
        </button>
        <Link to={routes.home}>
          <img alt="logo" src={logo} className={s.logo} />
        </Link>
      </div>
      <nav className={cn(s.navigation, { [s.menuOpened]: menuOpened })}>
        <NavLink to={routes.home} className={s.link}>
          {t("header.navigation.home")}
        </NavLink>
        <NavLink to={routes.aboutUs} className={s.link}>
          {t("header.navigation.aboutUs")}
        </NavLink>
        <NavLink to={routes.careers} className={s.link}>
          {t("header.navigation.careers")}
        </NavLink>
        <NavLink to={routes.businessProcess} className={s.link}>
          {t("header.navigation.businessProcess")}
        </NavLink>
        <LinkWithSubroute
          title={t("header.navigation.industries")}
          subroutes={[
            {
              title: t("header.navigation.realEstate"),
              href: routes.industriesRealEstate,
            },
          ]}
        />
        <Button
          size={ButtonSize.Small}
          withoutHover
          href="/#"
          className={s.contactUs}
        >
          {t("header.contactUs")}
        </Button>
      </nav>
    </header>
  );
};

const LinkWithSubroute = ({
  title,
  subroutes,
}: {
  title: string;
  subroutes: { title: string; href: string }[];
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className={s.linkWithSubroute}>
      <div className={s.linkWithSubrouteWrapper}>
        <NavLink to={routes.industries} className={s.link}>
          {title}
        </NavLink>
        <button
          className={cn(s.toggleSubroutes, { [s.subroutesClosed]: !opened })}
          onClick={() => setOpened((o) => !o)}
        >
          <ArrowUpIcon />
        </button>
      </div>
      <div className={cn(s.linkPopup, { [s.subroutesOpened]: opened })}>
        {subroutes.map(({ href, title }) => (
          <NavLink to={href} className={cn(s.link, s.linkPopupItem)} key={href}>
            {title}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
