import { useTranslation } from "react-i18next";
import s from "./banner-section.module.css";
import { Button } from "shared/ui-kit/button/button";
import { Slide, Zoom } from "react-awesome-reveal";

export const BannerSection = () => {
  const { t } = useTranslation();

  return (
    <section className={s.root}>
      <div className={s.background}></div>
      <div className={s.filter} />
      <div className={s.container}>
        <Slide duration={1500} triggerOnce>
          <h4 className={s.subtitle}>{t("homePage.banner.subtitle")}</h4>
        </Slide>
        <Zoom triggerOnce>
          <h1 className={s.title}>{t("homePage.banner.title")}</h1>
        </Zoom>
        <Slide direction="up" triggerOnce>
          <Button href="/#">{t("homePage.banner.viewMore")}</Button>
        </Slide>
      </div>
    </section>
  );
};
