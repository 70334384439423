import { useTranslation } from "react-i18next";
import s from "./page-under-construction.module.css";
import { ReactComponent as GearsIcon } from "./assets/gears.svg";

export const PageUnderConstruction = () => {
  const { t } = useTranslation();

  return (
    <main className={s.root}>
      <h1 className={s.title}>{t("pageUnderConstruction")}</h1>
      <div className={s.iconWrapper}>
        <GearsIcon className={s.icon} />
      </div>
    </main>
  );
};
