import { useTranslation } from "react-i18next";
import s from "./footer.module.css";
import { ContactForm } from "./contact-form/contact-form";
import { ReactComponent as FacebookIcon } from "./assets/facebook.svg";
import { ReactComponent as TwitterIcon } from "./assets/twitter.svg";
import { ReactComponent as YoutubeIcon } from "./assets/youtube.svg";

const email = "admin@bposinnosight.com";
const phone = "+639084614614";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={s.root}>
      <div className={s.container}>
        <h4 className={s.title}>{t("footer.title")}</h4>
        <div className={s.body}>
          <section className={s.left}>
            <h6 className={s.subtitle}>{t("footer.subtitle")}</h6>
            <h6 className={s.text}>{t("footer.text")}</h6>
            <p className={s.contact}>
              {t("footer.email")}: <a href={`mailto: ${email}`}>{email}</a>
            </p>
            <p className={s.contact}>
              {t("footer.phone")}: <a href={`tel: ${phone}`}>{phone}</a>
            </p>
            <div className={s.social}>
              <a className={s.socialItem} href="/#">
                <FacebookIcon className={s.socialIcon} />
              </a>
              <a className={s.socialItem} href="/#">
                <TwitterIcon className={s.socialIcon} />
              </a>
              <a className={s.socialItem} href="/#">
                <YoutubeIcon className={s.socialIcon} />
              </a>
            </div>
          </section>
          <section className={s.right}>
            <ContactForm />
          </section>
        </div>
      </div>
    </footer>
  );
};
