import { Button, ButtonSize } from 'shared/ui-kit/button/button';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { toast, ToastOptions } from 'react-toastify';
import s from './contact-form.module.css';

export interface ContactFormData {
  firstName: string;
  lastName: string;
  email: string;
  message?: string;
}

export const ContactForm = () => {
  const {t} = useTranslation();

  const methods = useForm<ContactFormData>();
  const {
    handleSubmit,
    reset,
  } = methods;

  const notify = (message: string, context: ToastOptions) => toast(message, context);

  const onSubmit = async (data: ContactFormData) => {
    // const res = await sendEmail(data); //TODO: uncomment when backend is ready
    const res = true;
    if (res) {
      notify(t('contactForm.form.emailSuccess'), {type: 'success'});
      reset();
    }
  };

  return (
    <div className={s.root}>
      <FormProvider {...methods}>
        <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            validation={{ required: true }}
            name="firstName"
            placeholder={t('contactForm.firstName')}
          />
          <Input
            name="lastName"
            placeholder={t('contactForm.lastName')}
            validation={{ required: true }}
          />
          <Input
            validation={{ required: true, email: true }}
            name="email"
            placeholder={t('contactForm.email')}
          />
          <Input
            name="message"
            placeholder={t('contactForm.message')}
            rows={7}
          />
        <Button size={ButtonSize.Small} withoutHover type="submit">
         {t('contactForm.submit')}
        </Button>
       </form>
      </FormProvider>
    </div>
  );
};

const Input = ({
                 name,
                 validation,
                 placeholder,
                 rows,
               }: {
  name: keyof ContactFormData;
  validation?: { required?: boolean; email?: boolean };
  placeholder?: string;
  rows?: number;
}) => {
  const {t} = useTranslation();
  const {
    register,
    formState: {errors},
  } = useFormContext<ContactFormData>();

  const required = !!validation?.required;
  const email = !!validation?.email;

  const error = errors[name];

  return (
    <div id={name} className={s.inputRoot}>
      {rows ? (
        <textarea
          placeholder={placeholder}
          rows={rows}
          {...register(name, {
            required: required && t('contactForm.form.required'),
            pattern: email
              ? {
                value: emailValidationPattern,
                message: t('contactForm.form.emailInvalid'),
              }
              : undefined,
          })}
        />
      ) : (
        <input
          placeholder={placeholder}
          {...register(name, {
            required: required && t('contactForm.form.required'),
            pattern: email
              ? {
                value: emailValidationPattern,
                message: t('contactForm.form.emailInvalid'),
              }
              : undefined,
          })}
        />
      )}
      {error && <div className={s.error}>{t(error.message || '')}</div>}
    </div>
  );
};

const emailValidationPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;